import React from "react";

import { useTranslation } from "react-i18next";

const Idea = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <section
        id="AppFeatures"
        className="section-block features-style3"
        data-scroll-index={2}
      >
        <div className="bubbles-animate">
          <div className="bubble b_one" />
          <div className="bubble b_two" />
          <div className="bubble b_three" />
          <div className="bubble b_four" />
          <div className="bubble b_five" />
          <div className="bubble b_six" />
        </div>
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6">
              <div className="section-header-style2">
                <h2>ماذا نقدم في حقوق تقنية ؟</h2>
                <p>
                  نحن نقدم حلولًا تقنية متطورة لمؤسسات القانون تساعدهم على تحسين
                  جودة خدماتهم وزيادة الكفاءة في العمل، ونسعى دائمًا لتلبية
                  احتياجات عملائنا بأحدث التقنيات والمنتجات الذكية. فنحن نؤمن
                  بأن الابتكار التقني هو المفتاح الأساسي لنجاح المؤسسات، ولذلك
                  نعمل جاهدين لتقديم حلول فريدة من نوعها وفعالة لمساعدة عملائنا
                  في تحقيق أهدافهم.
                  <br />
                </p>
                <div className="btn-read-more">
                  <a className="btn theme-btn" href="#" data-scroll-nav={8}>
                    اطلب خدماتنا
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                {/* Start Features item */}
                <div className="col-md-6">
                  <div className="feature-block">
                    <span className="feature-icon">
                      <i className="flaticon flaticon-idea" />
                    </span>
                    <h3>تصميم جذاب</h3>
                    <p>تصاميم تجذب العين وتعزز الهوية البصرية</p>
                  </div>
                </div>
                {/* End Features item */}
                {/* Start Features item */}
                <div className="col-md-6">
                  <div className="feature-block offset-top">
                    <span className="feature-icon">
                      <i className="flaticon flaticon-layers" />
                    </span>
                    <h3>سرعة فائقة</h3>
                    <p>منتجات تعمل بسلاسة وسرعة عالية.</p>
                  </div>
                </div>
                {/* End Features item */}
                {/* Start Features item */}
                <div className="col-md-6">
                  <div className="feature-block">
                    <span className="feature-icon">
                      <i className="flaticon flaticon-fingerprint" />
                    </span>
                    <h3>سهل الاستخدام</h3>
                    <p>واجهات سهلة وبديهية لتجربة استخدام مريحة.</p>
                  </div>
                </div>
                {/* End Features item */}
                {/* Start Features item */}
                <div className="col-md-6">
                  <div className="feature-block offset-top">
                    <span className="feature-icon">
                      <i className="flaticon flaticon-app" />
                    </span>
                    <h3>التميز</h3>
                    <p>حلول مخصصة تلبي الاحتياجات الفريدة للعملاء.</p>
                  </div>
                </div>
                {/* End Features item */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default Idea;
