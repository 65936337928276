import React from "react";

import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <section class="start_home demo1"  data-scroll-index={1}>
        <div class="banner_top">
          <div class="container">
            <div class="row">
              <div class="col-md-6 start-home-content">
                <h1>حقوق تقنية</h1>
                <p>
                  نقدم أفضل منتجات وحلول تقنية لتمكين المؤسسات في المجال
                  القانوني لنساعدهم في تقديم افضل جودة للعملاء
                </p>

              </div>
              <div class="col-md-6 start-home-img">
                <img
                  class="img-fluid"
                  src="./assets/images/banner-home-2.svg"
                  alt="Img"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="wave-area">
          <div class="wave"></div>
          <div class="wave"></div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default About;
