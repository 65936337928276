import React from "react";

import { useTranslation } from "react-i18next";

const Principles = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <section
        id="AppFeatures"
        className="section-block features-style1"
        data-scroll-index={4}
      >
        <div className="circls-features active">
          <div className="circle-1" />
          <div className="circle-2" />
          <div className="circle-3" />
          <div className="circle-4" />
          <div className="circle-x" />
        </div>
        <div className="container">
          <div className="section-header">
            <h2>الممزيات الاساسية</h2>
            <p>
              لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت
              دو أيوسمود تيمبور أنكايديد
              <br />
              يوت انيم أد مينيم فينايم,كيواس نوستريد أكسير.
            </p>
          </div>
          <div className="row">
            {/* Start Features Item */}
            <div className="col-md-4">
              <div className="feature-block">
                <span className="feature-icon icon-1">
                  <i className="ti-wand" />
                </span>
                <h3>تصميم حديث</h3>
                <p>تصميم حديث وعصري يتماشى مع أحدث التقنيات والاتجاهات.</p>
              </div>
            </div>
            {/* End Features Item */}
            {/* Start Features Item */}
            <div className="col-md-4">
              <div className="feature-block">
                <span className="feature-icon icon-2">
                  <i className="ti-gift" />
                </span>
                <h3>جودة عالية</h3>
                <p>جودة عالية في التصميم والبرمجة والتطوير.</p>
              </div>
            </div>
            {/* End Features Item */}
            {/* Start Features Item */}
            <div className="col-md-4">
              <div className="feature-block">
                <span className="feature-icon icon-3">
                  <i className="ti-announcement" />
                </span>
                <h3>توافق عالي</h3>
                <p>متوافق مع مختلف الأنظمة والأجهزة والمتصفحات.</p>
              </div>
            </div>
            {/* End Features Item */}
            {/* Start Features Item */}
            <div className="col-md-4">
              <div className="feature-block">
                <span className="feature-icon icon-4">
                  <i className="ti-headphone-alt" />
                </span>
                <h3>دعم مستمر</h3>
                <p>دعم فني وتقني مستمر على مدار الساعة.</p>
              </div>
            </div>
            {/* End Features Item */}
            {/* Start Features Item */}
            <div className="col-md-4">
              <div className="feature-block">
                <span className="feature-icon icon-5">
                  <i className="ti-bar-chart" />
                </span>
                <h3>سهل الاستخدام</h3>
                <p>واجهة بسيطة وسهلة الاستخدام دون تعقيد.</p>
              </div>
            </div>
            {/* End Features Item */}
            {/* Start Features Item */}
            <div className="col-md-4">
              <div className="feature-block">
                <span className="feature-icon icon-6">
                  <i className="ti-pencil-alt" />
                </span>
                <h3>تصميم خاص</h3>
                <p>تصميم فريد وخاص يتناسب مع احتياجات العميل.</p>
              </div>
            </div>
            {/* End Features Item */}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default Principles;
