import React from "react";

import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <footer>
        <div className="shape-top" />
        <div className="container">
          {/* End Footer Top  Area */}
          <div className="top-footer">
            <div className="row justify-content-around">
              {/* Start Column 1 */}
              <div className="col-md-4">
                <div className="footer-logo">
                  <img
                    src="./assets/images/logo.png"
                    className="img-fluid"
                    alt="Img"
                  />
                </div>
                <p>
                  شركة تقنية متخصصة في المجال القانوني وتوفير خدمات تقنية لهم.
                </p>
                <div className="footer-social-links">
                  <a href="https://twitter.com/TeK_Rights">
                    <i className="ti-twitter-alt" />
                  </a>

                </div>
              </div>
              {/* End Column 1 */}
              {/* Start Column 2 */}
              <div className="col-md-2">
                <h4 className="footer-title">تعرف علينا</h4>
                <ul className="footer-links">
                  <li className="nav-item">
                    <a href="#" data-scroll-nav={1}>
                      الرئيسية
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" data-scroll-nav={2}>
                      عن عقود
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" data-scroll-nav={3}>
                      مبادئنا
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" data-scroll-nav={4}>
                      ما يميزنا
                    </a>
                  </li>
                </ul>
              </div>
              {/* End Column 2 */}
              {/* Start Column 3 */}
              <div className="col-md-2">
                <h4 className="footer-title">تواصل معنا</h4>
                <ul className="footer-links">
                  <li className="nav-item">
                    <a href="#" data-scroll-nav={5}>
                      الاسئلة الشائعة
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" data-scroll-nav={6}>
                      تواصل معنا
                    </a>
                  </li>
                </ul>
              </div>
              {/* End Column 3 */}
            </div>
          </div>
          {/* End Footer Top  Area */}
          {/* Start Copyrights Area */}
          <div className="copyrights">
            <p>
              Copyrights © 2023. Designed by <i className="flaticon-like-2" />{" "}
              <a href="https://TKRighrts.com">Tech Rights</a>.
            </p>
          </div>
          {/* End Copyrights Area */}
        </div>
      </footer>
    </React.Fragment>
  );
};
export default Footer;
