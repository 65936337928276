import React from "react";
import { useTranslation } from "react-i18next";
import About from "./components/About";
import FAQ from "./components/FAQ";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Idea from "./components/Idea";
import Features from "./components/Features";
import Principles from "./components/Principles";
import Header from "./components/Header";

import "./Home.css";

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <About />
        <Idea />
        <Features />
        <Principles />

        <FAQ />
        <Contact />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Home;
