import React, { useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [isSentRequest, setIsSentRequest] = useState(false);
  const [IsFailed, setIsFailed] = useState(false);
  const [IsSuccessed, setIsSuccessed] = useState(false);
  const [IsSent, setIsSent] = useState(false);

  const form = useRef();
  const { t } = useTranslation();

  const sendEmail = (e) => {
    setIsSentRequest(true);
    setIsSuccessed(false);
    setIsFailed(false)
    e.preventDefault();
    if (!IsSent) {
      emailjs
        .sendForm(
          "service_9y7n2nq",
          "template_gaipzgs",
          form.current,
          "xEwbPm-gv9PbTIh57"
        )
        .then(
          (result) => {
            setIsSuccessed(true);
            setIsSent(true);
          },
          (error) => {
            setIsSentRequest(false);
            setIsFailed(true);
          }
        );
    }else{
      setIsSuccessed(true)

    }
  };
  return (
    <React.Fragment>
      <section id="contact" className="section-block" data-scroll-index={6}>
        <div className="bubbles-animate">
          <div className="bubble b_one" />
          <div className="bubble b_two" />
          <div className="bubble b_three" />
          <div className="bubble b_four" />
          <div className="bubble b_five" />
          <div className="bubble b_six" />
        </div>
        <div className="container">
          <div className="row">
            {/* Start Contact Information */}
            <div className="col-md-5">
              <div className="section-header-style2">
                <h2>تواصل معنا</h2>
                <p>

                </p>
              </div>
              <div className="contact-details">
                {/* Start Contact Block */}
                <div className="contact-block">
                  <h4>عنوان مكتبنا</h4>
                  <div className="contact-block-side">
                    <i className="flaticon-route" />
                    <p>
                      <span>الرياض </span>
                      <span>المملكة العربية السعودية </span>
                    </p>
                  </div>
                </div>
                {/* End Contact Block */}
                {/* Start Contact Block */}
                <div className="contact-block">
                  <h4>ساعات العمل</h4>
                  <div className="contact-block-side">
                    <i className="flaticon-stopwatch-4" />
                    <p>
                      <span>الاحد - الخميس </span>
                      <span>9:00 صباحاً - 5:00 مساءً</span>
                    </p>
                  </div>
                </div>
                {/* End Contact Block */}
                {/* Start Contact Block */}
                <div className="contact-block">
                  <h4>الهاتف</h4>
                  <div className="contact-block-side">
                    <i className="flaticon-smartphone-7" />
                    <p>
                    <span>+966 55 569 0900</span>
                      <span>+966 59 989 9207</span>
                    </p>
                  </div>
                </div>
                {/* End Contact Block */}
                {/* Start Contact Block */}
                <div className="contact-block">
                  <h4>البريد الالكتروني</h4>
                  <div className="contact-block-side">
                    <i className="flaticon-paper-plane-1" />
                    <p>
                      <span>s.alshehri@tkrights.com</span>
                    </p>
                  </div>
                </div>
                {/* End Contact Block */}
              </div>
            </div>
            {/* End Contact Information */}
            {/* Start Contact form Area */}
            <div className="col-md-7">
              <div className="contact-shape">
                <img
                  src="./assets/images/shapes/contact-form.png"
                  className="img-fluid"
                  alt="Img"
                />
              </div>
              <div className="contact-form-block">
                <div className="section-header-style2">
                  <h2>دعنا نتحدث عن فكرتك</h2>
                  <p>اترك رسالة لنا و سنعيد التواصل معك </p>
                </div>
                <form ref={form} onSubmit={sendEmail} className="contact-form">
                  <input
                    type="text"
                    name="Name"

                    className="form-control"
                    placeholder="الأسم"
                    required
                  />
                  <input
                    type="email"
                    name="Email"

                    className="form-control"
                    placeholder="البريد الالكتروني"
                    required
                  />
                  <textarea
                    className="form-control"
                    name="Message"

                    placeholder="الرسالة"
                    defaultValue={""}
                    required
                  />
                                  <div style={{ display: IsSuccessed ? "block" : "none" }}>
                  <div class="alert alert-success d-flex align-items-center justify-content-center ">
                    <div>تم إرسال الرسالة</div>
                  </div>
                </div>

                <div style={{ display: IsFailed ? "block" : "none" }}>
                  <div
                    class="alert alert-danger d-flex align-items-center justify-content-center "
                    role="alert"
                  >
                    <div>فشل ارسال الرسالة , الرجاء المحاولة لاحقا </div>
                  </div>
                </div>
                <button
                  className="form__btn btn btn--uppercase btn--orange"
                  disabled={isSentRequest && !IsSuccessed ? true : false}
                >
                  {isSentRequest && !IsSuccessed ? (
                    <div> جاري الارسال... </div>
                  ) : (
                    <span>ارسل الرسالة</span>
                  )}
                </button>
                  <button className="btn theme-btn">ارسل الرسالة</button>
                </form>
              </div>
            </div>
            {/* End Contact form Area */}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default Contact;
