import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [lng, setLng] = useState("en");
  const changeLanguage = () => {
    if (lng === "en") {
      setLng("ar");
      i18n.changeLanguage("ar");

      const about = document.getElementById("about");
      about.style.direction = "rtl";
      about.style.textAlignLast = "start";
      about.style.textAlign = "right";

      const menu = document.getElementById("menu");
      menu.style.flexDirection = "row-reverse";

      Array.from(document.getElementsByClassName("menu__item")).forEach(
        function (element, index, array) {
          element.style.marginRight = "0px";
          element.style.marginLeft = "35px";
        }
      );

      const aboutme = document.getElementById("about-me-ar");
      aboutme.classList.add("aboutme-ar");

      const Products = document.getElementById("Products");
      Products.style.direction = "rtl";
      Products.style.textAlignLast = "start";

      Array.from(document.getElementsByClassName("cases__item-text")).forEach(
        function (element, index, array) {
          element.classList.add("text-products");
        }
      );

      Array.from(document.getElementsByClassName("mobile-menu")).forEach(
        function (element, index, array) {
          element.style.textAlignLast = "end";
        }
      );
      const header = document.getElementById("header");

      header.style.flexFlow = "row-reverse";
    } else {
      setLng("en");
      i18n.changeLanguage("en");
      const about = document.getElementById("about");
      about.style.direction = "ltr";
      about.style.textAlign = "left";

      const aboutme = document.getElementById("about-me-ar");
      aboutme.classList.remove("aboutme-ar");

      const Products = document.getElementById("Products");
      Products.style.direction = "ltr";
      const Products2 = document.getElementsByClassName("cases__item-text");
      Array.from(document.getElementsByClassName("cases__item-text")).forEach(
        function (element, index, array) {
          element.classList.remove("text-products");
        }
      );
      Array.from(document.getElementsByClassName("mobile-menu")).forEach(
        function (element, index, array) {
          element.style.textAlignLast = " start";
        }
      );
      const header = document.getElementById("header");

      header.style.flexFlow = "";
    }
  };

  return (
    <React.Fragment>
      <header className="header-area" id="header-area">
        <nav className="navbar navbar-expand-md fixed-top">
          <div className="container">
            <div className="site-logo">
              <a className="navbar-brand" href="index.html">
                <img
                  src="./assets/images/logo.png"
                  className="img-fluid"
                  alt="Img"
                />
              </a>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <i className="ti-menu" />
              </span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a href="#" data-scroll-nav={1}>
                    الرئيسية
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#" data-scroll-nav={2}>
                    عن عقود
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#" data-scroll-nav={3}>
                    مبادئنا
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#" data-scroll-nav={4}>
                    ما يميزنا
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#" data-scroll-nav={5}>
                    الاسئلة الشائعة
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#" data-scroll-nav={6}>
                    تواصل معنا
                  </a>
                </li>

              </ul>
            </div>
          </div>
        </nav>
      </header>
    </React.Fragment>
  );
};
export default Header;
