import React from "react";

import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <section
        id="how-it-work"
        className="section-block features-style4"
        data-scroll-index={3}
      >
        <div className="container">
          <div className="section-header">
            <h1>مبادئنا</h1>
            <p>
              في حقوق تقنية, نحرص دائمًا على الحفاظ على معايير عالية في جميع مراحل
              العمل، من التصميم والتطوير إلى تقديم الخدمات والدعم. ونسعى جاهدين
              لتحسين وتطوير عملنا بشكل مستمر لتلبية احتياجات وتوقعات عملائنا،
              وذلك باستخدام أفضل الممارسات والتقنيات المتاحة في صناعتنا.
            </p>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="img-box">
                <img src="./assets/images/how-work.png" alt="Img" />
              </div>
            </div>
            <div className="col-md-6">
              {/* Start Block 1 */}
              <div className="description-block">
                <div className="inner-box">
                  <div className="step_num">
                    <img src="./assets/images/step1.png" alt="Img" />
                  </div>
                  <h3>جودة عالية</h3>
                  <p>
                    نحرص على استخدام أحدث التقنيات والممارسات لتقديم منتجات
                    وخدمات عالية الجودة تلبي احتياجات العملاء وتتجاوز توقعاتهم.
                  </p>
                </div>
              </div>
              {/* End Block 1 */}
              {/* Start Block 2 */}
              <div className="description-block">
                <div className="inner-box">
                  <div className="step_num">
                    <img src="./assets/images/step2.png" alt="Img" />
                  </div>
                  <h3>سرعة التطوير</h3>
                  <p>
                    نحرص على تسريع عملية التطوير والابتكار لتقديم منتجات وخدمات
                    جديدة بأفضل الخصائص والوظائف في أقصر وقت ممكن.
                  </p>
                </div>
              </div>
              {/* End Block 2 */}
              {/* Start Block 3 */}
              <div className="description-block">
                <div className="inner-box">
                  <div className="step_num">
                    <img src="./assets/images/step3.png" alt="Img" />
                  </div>
                  <h3>دعم مستمر</h3>
                  <p>
                    نعمل جاهدين على توفير الدعم المستمر لعملائنا، سواءً في صيانة
                    المنتجات وتحديثاتها أو توفير الدعم الفني والتدريب المستمر،
                    لضمان حصولهم على تجربة استخدام مريحة وناجحة.
                  </p>
                </div>
              </div>
              {/* End Block 3 */}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default About;
