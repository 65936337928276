import React from "react";

import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <section id="faqs" className="section-block" data-scroll-index={5}>
        <div className="container">
          <div className="section-header">
            <h2>الأسئلة الشائعة</h2>
            <p>

            </p>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="img-box">
                <img
                  src="./assets/images/faq2.svg"
                  className="img-fluid"
                  alt="Img"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="accordion" id="accordionExample">
                {/* Start Faq item */}
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        ما هي خدمات حقوق تقنية
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      نحن نقدم خدمات تقنية للمكاتب القانونية والمحامين، بما في
                      ذلك تصميم مواقع الويب، وحلول تحسين محركات البحث، وتطوير
                      التطبيقات المخصصة للشركة.
                    </div>
                  </div>
                </div>
                {/* End Faq item */}
                {/* Start Faq item */}
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        هل يمكن تخصيص الخدمات وفقًا لاحتياجاتي؟
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      نعم، يمكننا تخصيص حلولنا لتلبية احتياجات عملائنا الفريدة
                      وتحقيق أهدافهم.
                    </div>
                  </div>
                </div>
                {/* End Faq item */}
                {/* Start Faq item */}
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        هل تقدمون دعمًا فنيًا مستمرًا؟
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      نعم، نحن نقدم دعمًا فنيًا مستمرًا لعملائنا عبر البريد
                      الإلكتروني والهاتف.
                    </div>
                  </div>
                </div>
                {/* End Faq item */}
                {/* Start Faq item */}
                <div className="card">
                  <div className="card-header" id="headingFour">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        هل تضمنون جودة الخدمات التي تقدمونها؟
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseFour"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      نعم، نحن نعمل بجد لتقديم أفضل جودة لعملائنا ونضمن جودة
                      عالية لجميع الخدمات التي نقدمها.
                    </div>
                  </div>
                </div>
                {/* End Faq item */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default FAQ;
